.event-details-header {
    display: flex;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.event-details-header-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.event-details-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    gap: 20px;
    padding: 20px;
}

.event-details-name > div {
    display: flex;
    justify-content: space-around;
    gap: 30px;
}

.event-details-section {
    margin-top: 20px;
    display: flex;
    padding: 20px;
}

.event-details-teams-wrapper {
    display: flex;
    width: 100%;
    flex: 2;
}

.vote-event-teams-wrapper {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 40px;

    & > button {
        margin: 0 auto;
    }
}

.event-details-teams {
    flex: 2;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.event-details-team {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-details-team > h3 {
    margin-bottom: 30px;
}

.event-details-teams-empty {
    height: 120px;
    margin: 10px;

    & > div {
        height: 110px;
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border: 1px solid var(--light-grey);
        border-radius: 15px;
        filter: opacity(70%);
        box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.4);
    }

    & > div.action {
        filter: opacity(100%);
    }

    & > div > h3 {
        margin: 5px 0;
        text-align: center;
    }

    & > div > i {
        height: 35px;
        cursor: pointer;
    }
}

.event-details-user-wrapper {
    display: flex;
    gap: 20px;
}

.closed-event-stats {
    padding-top: 40px;
}

.closed-event-like {
    display: flex;
    align-items: center;
    gap: 10px;
}

.event-details-user {
    position: relative;
    height: 120px;
    width: 100px;
    margin: 10px 0;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-details-team-user-invitation-label {
    position: absolute;
    right: -12px;
    z-index: 1;
    border: 1px solid var(--green);
    border-radius: 15px;
    padding: 0 3px;
    background-color: var(--green);
}

.event-details-user > p {
    margin: 10px 0;
}

.event-details-user > svg {
    height: 40px;
    cursor: pointer;
}

.event-details-main {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    min-height: 500px;
}

.event-details-timer {
    margin: 10px 0;

    & > h3 {
        margin-bottom: 10px;
    }
}

.event-details-subheader {
    display: flex;
    flex: 1;
}

.event-details-actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    flex: 1;
}

.event-details-requests {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1 1;
}

.event-details-team-user-text {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-details-header-invitation {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.event-details-header-invitation-actions {
    display: flex;
    gap: 20px;
}

@media only screen and (max-width: 800px) {
    .event-details {
        padding: 15px;
    }

    .event-details-section {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .event-details-info-wrapper {
        flex: 1;
        padding: 0 20px;
        margin: 0 auto;
    }

    .event-details-teams {
        padding: 0;
        margin-top: 20px;
        flex: 2;
        display: flex;
        justify-content: space-around;
    }

    .event-details-teams-wrapper {
        flex-direction: column;
        gap: 25px;
    }

    .event-details-header {
        margin: 0;
    }

    .event-details-subheader {
        display: flex;
        flex-direction: column;
    }

    .event-details-actions {
        margin-top: 20px;
        justify-content: flex-start;
    }
}
