.event-join-requests-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1;
}

.event-join-request {
    display: flex;
    align-items: center;
    gap: 20px;
}

.event-join-request-user {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 30px;
}
